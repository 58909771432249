var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"custom-nav"},[_c('div',{staticClass:"d-flex px-0 px-sm-15 px-md-15 nav-position"},[(_vm.isMobile)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-avatar',{attrs:{"width":"120","heigth":"75","tile":""},on:{"click":function($event){_vm.$router.push({ name: 'home' }).catch(function (err) { return err; })}}},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"contain":"","src":require("@/assets/img/edifier-logo-color.svg")},on:{"mouseover":function($event){_vm.megaMenu = false}}})],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"px-5",staticStyle:{"color":"white","cursor":"default"},on:{"mouseover":function($event){_vm.megaMenu = false}}},[_vm._v(" . ")]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"nav-row"},[_c('span',{staticStyle:{"position":"relative"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{class:hover
                  ? 'nav-item-hover text-capitalize mx-4 py-4'
                  : 'nav-item text-capitalize mx-4 py-4',style:(_vm.currentURL.toLowerCase().includes('audifonos') ||
                _vm.headphonesMenu
                  ? 'font-weight: 600;'
                  : ''),on:{"mouseover":function($event){_vm.headphonesMenu = true;
                _vm.speakersMenu = false;}}},[_vm._v(" Audifonos ")])]}}],null,false,3256210787)}),(_vm.headphonesMenu)?_c('div',{staticClass:"menu-dropdown mega_menu animate__animated animate__fadeIn animate__faster",staticStyle:{"background-color":"#f8f8f8"},on:{"mouseleave":function($event){_vm.headphonesMenu = false}}},[_vm._l((_vm.headphonesOptions),function(item,index){return _c('div',{key:index,staticClass:"row-menu",on:{"click":function () { return _vm.HandlerGoToCategory(item.id, item.type, item.title); }}},[(item.icon)?_c('img',{staticStyle:{"margin-right":"8px","height":"24px","width":"24px"},attrs:{"height":"24","width":"24","src":item.icon}}):_vm._e(),_c('div',{staticClass:"row-menu-title"},[_vm._v(_vm._s(item.title))])])}),_c('div',{staticClass:"see-all-text",on:{"click":function () { return _vm.HandlerGoToCategory(27, 1, 'Audifonos'); }}},[_vm._v(" Ver todos ")])],2):_vm._e()],1),_c('span',{staticStyle:{"position":"relative"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('span',{class:hover
                  ? 'nav-item-hover text-capitalize mx-4 py-4'
                  : 'nav-item text-capitalize mx-4 py-4',style:(_vm.currentURL.toLowerCase().includes('parlantes') || _vm.speakersMenu
                  ? 'font-weight: 600;'
                  : ''),on:{"mouseover":function($event){_vm.speakersMenu = true;
                _vm.headphonesMenu = false;}}},[_vm._v(" Parlantes ")])]}}],null,false,3295818246)}),(_vm.speakersMenu)?_c('div',{staticClass:"menu-dropdown mega_menu animate__animated animate__fadeIn animate__faster",staticStyle:{"background-color":"#f8f8f8"},on:{"mouseleave":function($event){_vm.speakersMenu = false}}},[_vm._l((_vm.spekersOptions),function(item,index){return _c('div',{key:index,staticClass:"row-menu",on:{"click":function () { return _vm.HandlerGoToCategory(item.id, item.type, item.title); }}},[(item.icon)?_c('img',{staticStyle:{"margin-right":"8px","height":"24px","width":"24px"},attrs:{"height":"24","width":"24","src":item.icon}}):_vm._e(),_c('div',{staticClass:"row-menu-title"},[_vm._v(_vm._s(item.title))])])}),_c('div',{staticClass:"see-all-text",on:{"click":function () { return _vm.HandlerGoToCategory(7, 1, 'Parlantes'); }}},[_vm._v(" Ver todos ")])],2):_vm._e()],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('span',{class:hover
                ? 'nav-item-hover text-capitalize mx-4 py-4'
                : 'nav-item text-capitalize mx-4 py-4',style:(_vm.currentURL.toLowerCase().includes('about_us')
                ? 'font-weight: 600;'
                : ''),on:{"click":function($event){return _vm.goToMenu('about_us')},"mouseover":function($event){return _vm.closeMenu()}}},[_vm._v(" Nosotros ")])]}}],null,false,2675761252)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('span',{class:hover
                ? 'nav-item-hover text-capitalize mx-4 py-4'
                : 'nav-item text-capitalize mx-4 py-4',style:(_vm.currentURL.toLowerCase().includes('show_room')
                ? 'font-weight: 600; width: 56px;'
                : 'width: 56px;'),on:{"click":function($event){return _vm.goToMenu('show_room')},"mouseover":function($event){return _vm.closeMenu()}}},[_vm._v(" Tienda ")])]}}],null,false,335946192)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('span',{class:hover
                ? 'nav-item-hover text-capitalize mx-4 py-4'
                : 'nav-item text-capitalize mx-4 py-4',style:(_vm.currentURL.toLowerCase().includes('contact')
                ? 'font-weight: 600;'
                : ''),on:{"mouseover":function($event){return _vm.closeMenu()},"click":function($event){return _vm.goToMenu('contact')}}},[_vm._v(" Contacto ")])]}}],null,false,3274911212)})],1):_vm._e(),_c('v-spacer'),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"},on:{"click":_vm.activeSearch}},[_vm._v(" mdi-magnify ")]),(!_vm.isMobile && _vm.favoriteProduct.length > 0)?_c('div',[(_vm.isAuth)?_c('v-btn',{staticClass:"mr-1 ml-2",attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'product_favorite' })}}},[_c('v-icon',{staticClass:"animate__animated animate__pulse animate__infinite",attrs:{"color":"red lighten-1"}},[_vm._v(" mdi-heart ")])],1):_vm._e()],1):_vm._e(),(_vm.isAuth == true)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isAuth)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 ml-2 elevation-0",attrs:{"color":"#E9E9E9","icon":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-account-circle-outline ")])],1):_vm._e()]}}],null,false,3030908379)},[_c('span',[_vm._v("Perfil")])])],1):_vm._e(),(_vm.isMobile == true && _vm.isAuth == false)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account")])],1):_vm._e(),(_vm.isMobile && _vm.isAuth == true)?_c('v-btn',{staticClass:"ml-0 mr-0",attrs:{"color":"black","text":"","icon":""},on:{"click":function($event){return _vm.HandlerLogout()}}},[_c('v-icon',[_vm._v("mdi-exit-to-app ")])],1):_vm._e(),_c('v-menu',{attrs:{"open-on-hover":"","close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","color":"#00A0E9","dark":""},on:{"click":function($event){return _vm.HandlerRouter('cart')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cart-outline")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(
            _vm.productCartState.shopping_cart_items != null &&
              _vm.productCartState.shopping_cart_items.length > 0
          )?_c('v-card',[_vm._l((_vm.productCartState.shopping_cart_items),function(item,index){return _c('div',{key:index,staticClass:"pt-3 px-5 d-flex"},[_c('div',[(item.publication.images == null)?_c('v-avatar',{attrs:{"tile":""}},[_c('img',{attrs:{"height":"200","width":"100%","contain":"","src":require("@/assets/img/no_image.jpg")}})]):_c('v-avatar',{attrs:{"tile":"","size":"100"}},[_c('v-img',{attrs:{"contain":"","src":item.publication.images[0],"lazy-src":item.publication.images[0],"alt":"Product Image"}})],1)],1),_c('div',{staticClass:"pl-3 align-self-center"},[_c('div',{},[_vm._v(_vm._s(item.publication.keywords))]),(
                  item.publication != null && item.publication.price != null
                )?_c('div',{staticClass:"mt-2"},[_vm._v(" $ "+_vm._s(_vm._f("currency")(item.publication.price.pvp))+" ")]):_vm._e()])])}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","small":"","color":"#00a0e9","rounded":""},on:{"click":function($event){return _vm.HandlerRouter('cart')}}},[_vm._v(" Lista de compra ")])],1)],2):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isMobile == false && _vm.isAuth == true)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"black"},on:{"click":_vm.HandlerLogout}},'v-icon',attrs,false),on),[_vm._v(" mdi-exit-to-app ")]):_vm._e()]}}])},[_c('span',[_vm._v("Salir")])]),(_vm.isMobile == false && _vm.isAuth == false)?_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":""},on:{"click":function($event){return _vm.HandlerRouter('login')}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-outline")]),_vm._v(" Ingresar ")],1):_vm._e()],1),(_vm.megaMenu)?_c('div',{staticClass:"mega_menu animate__animated animate__fadeIn animate__faster",staticStyle:{"background-color":"#f8f8f8"},on:{"mouseleave":function($event){_vm.megaMenu = false}}},[_c('v-container',[_c('v-row',{staticClass:"mb-10"},_vm._l((_vm.categories),function(category,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('div',{staticClass:"mt-7 mb-5 text-capitalize",style:(hover
                    ? 'font-size: 1.3em; cursor: pointer; color: #00A0E9'
                    : 'font-size: 1.3em; cursor: pointer'),on:{"click":function($event){return _vm.HandlerGetPublicProducts(category, 1)}}},[_vm._v(" "+_vm._s(category.name)+" ")])]}}],null,true)}),(
                category.name != 'Línea S' &&
                  category.name != 'Gaming' &&
                  category.name != 'Todo' &&
                  category.name != 'Portátiles'
              )?_c('div',_vm._l((category.sub_category),function(sub_cat,j){return _c('div',{key:j,staticClass:"text-capitalize mb-3"},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.HandlerGetPublicProducts(sub_cat, 2)}}},[_vm._v(" "+_vm._s(sub_cat.name)+" ")])])}),0):_vm._e()],1)}),1)],1)],1):_vm._e()]),_c('v-navigation-drawer',_vm._g(_vm._b({staticStyle:{"top":"70px","width":"280px"},attrs:{"color":"transparent","fixed":"","height":"auto","overlay-color":"secondary","overlay-opacity":"0","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_c('v-list',{staticClass:"list-menu",attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-expansion-panels',{attrs:{"accordion":""}},_vm._l((_vm.drawerItems),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[(!item.isDropdown)?_c('v-btn',{staticClass:"menu-btn",attrs:{"color":"black","text":""},on:{"click":function($event){return _vm.goToMenu(item.url)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-expansion-panel-content',_vm._l((item.items),function(itm,index){return _c('div',{key:index,staticClass:"row-menu",on:{"click":function () { return _vm.HandlerGoToCategory(itm.id, itm.type, item.title); }}},[(itm.icon)?_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"height":"24","width":"24","src":itm.icon}}):_vm._e(),_c('div',{staticClass:"row-menu-title"},[_vm._v(_vm._s(itm.title))])])}),0)],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }