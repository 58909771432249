var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"animate__animated animate__backInUp",staticStyle:{"box-shadow":"0 10px 15px -3px rgba(0, 0, 0, 0.1) !important","border-radius":"20px !important"},attrs:{"width":"600","flat":""}},[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"2em","font-weight":"380"}},[_vm._v(" Bienvenido ")]),_c('div',{staticClass:"text-center mt-3 mb-5",staticStyle:{"font-size":"0.87em","font-weight":"400","line-height":"1.375rem"}},[_vm._v(" Registra tu cuenta ")]),_c('ValidationObserver',{ref:"obsReg",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"font-weight-bold ml-3",staticStyle:{"color":"#666666"},attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 pl-3 pr-3 px-sm-3 pr-md-0",attrs:{"color":"black","dense":"","filled":"","flat":"","placeholder":"Ingresa tu nombre","error-messages":errors},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"font-weight-bold ml-3 ml-md-0",staticStyle:{"color":"#666666"},attrs:{"for":"Codigo Postal"}},[_vm._v(" Apellido ")]),_c('ValidationProvider',{attrs:{"name":"apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 ml-3 ml-sm-3 ml-md-0 pr-3",attrs:{"color":"black","dense":"","filled":"","flat":"","placeholder":"Ingresa tu apellido","error-messages":errors},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required|confirmed:email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-weight-bold ml-3",staticStyle:{"color":"#666666"},attrs:{"for":"email"}},[_vm._v(" Correo Electrónico ")]),_c('v-text-field',{staticClass:"mt-2 pl-3 pr-3 pr-sm-3 pr-md-0",attrs:{"autocomplete":"off","color":"black","dense":"","filled":"","flat":"","placeholder":"Ingresa tu correo electronico","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirmar correo","rules":"email|required","vid":"email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-weight-bold ml-3 ml-md-0",staticStyle:{"color":"#666666"},attrs:{"for":"email confirmar"}},[_vm._v(" Confirmar Correo ")]),_c('v-text-field',{staticClass:"mt-2 pr-3 ml-3 ml-sm-3 ml-md-0",attrs:{"autocomplete":"off","color":"black","dense":"","filled":"","flat":"","placeholder":"Confrima tu correo electronico","error-messages":errors},model:{value:(_vm.email_confirmation),callback:function ($$v) {_vm.email_confirmation=$$v},expression:"email_confirmation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"contraseña","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-weight-bold ml-3",staticStyle:{"color":"#666666"},attrs:{"for":"password"}},[_vm._v(" Contraseña ")]),_c('v-text-field',{staticClass:"mt-2 pl-3 pr-3 pr-sm-3 pr-md-0",attrs:{"autocomplete":"off","type":"password","color":"black","dense":"","filled":"","flat":"","placeholder":"Ingresa tu contraseña","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"font-weight-bold",staticStyle:{"color":"#666666"},attrs:{"for":"password confirmar"}},[_vm._v(" Confirmar Contraseña ")]),_c('v-text-field',{staticClass:"mt-2 pr-3 ml-3 ml-sm-3 ml-md-0",attrs:{"autocomplete":"off","type":"password","color":"black","dense":"","filled":"","flat":"","placeholder":"Confirma tu nombre contraseña","error-messages":errors},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"mx-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"block":"","loading":_vm.loading,"tile":"","large":"","elevation":"0","dark":"","color":"#00A0E9"},on:{"click":function($event){return passes(_vm.HandlerSignUp)}}},[_vm._v(" Registrar ")])],1)]),_c('v-col',{staticClass:"text-center mt-n7 mt-md-n5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"mt-6 mb-4 blue--text",staticStyle:{"cursor":"pointer"},on:{"click":_vm.emitEvent}},[_vm._v(" Ya tienes cuenta? Inicia sesion ")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }