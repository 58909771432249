var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],class:_vm.sticky ? 'header-sticky' : '',style:(_vm.sticky
        ? 'background-color: #ffffff; height: auto'
        : 'background-color: #ffffff; height: auto')},[_c('div',{staticStyle:{"background-color":"#c9cdd9","height":"0px"}}),_c('v-tabs',{staticClass:"d-flex justify-center",attrs:{"color":"#797A7E","background-color":"#FFFFFF","slider-color":"#0EA4E9"}},[_c('v-tab',{on:{"click":function($event){return _vm.$vuetify.goTo('#product')}}},[_vm._v(" "+_vm._s(_vm.dataProduct.keywords)+" ")]),_c('v-tab',{on:{"click":function($event){return _vm.$vuetify.goTo('#detalle_producto')}}},[_vm._v("Descripción")]),_c('v-tab',{on:{"click":function($event){return _vm.$vuetify.goTo('#especificaciones')}}},[_vm._v(" Especificaciones ")]),(
          _vm.dataProduct.product != null &&
            _vm.dataProduct.product.product_manual !== null
        )?_c('v-tab',{on:{"click":_vm.HandlerDowloadManual}},[(!_vm.loadingManual)?_c('span',[_vm._v("MANUAL DE USUARIO")]):_c('span',[_vm._v("...")])]):_vm._e()],1)],1),_c('v-sheet',{attrs:{"color":"#EBF1F7","id":"product"}},[(_vm.sticky)?_c('div',{staticClass:"py-15"}):_vm._e(),_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.name == 'lg' || _vm.$vuetify.breakpoint.name == 'xl'
          ? false
          : true}},[_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[(_vm.dataProduct.images != null)?_c('v-img',{attrs:{"src":_vm.dataProduct.images[0],"lazy-src":_vm.dataProduct.images[0],"contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,false,4034393411)}):_vm._e(),(!_vm.$vuetify.breakpoint.smAndDown && !_vm.messageProductAdd)?_c('div',{staticClass:"py-15"}):_vm._e()],1),_c('v-col',{staticClass:"ml-md-15 d-flex flex-column",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('div',[_c('div',{staticClass:"title-font text-uppercase"},[_vm._v(" "+_vm._s(_vm.dataProduct.keywords)+" ")]),_c('p',{staticClass:"mt-1"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.dataProduct.resume)}})])]),_c('div',{staticClass:"mt-auto"},[_c('price-details',{attrs:{"prices":_vm.dataProduct.price}}),_c('div',{staticClass:"d-flex justify-space-between mt-5"},[(_vm.dataProduct)?_c('cp-information',{staticClass:"mt-auto",staticStyle:{"font-size":"18px"},attrs:{"dataProduct":_vm.dataProduct,"authUser":_vm.authUser}}):_vm._e()],1),(
                _vm.validateStock() &&
                  _vm.validateUmbral() &&
                  _vm.dataProduct.store.out_stock == false
              )?_c('div',{staticClass:"d-flex align-center justify-start py-1 mt-10"},[_c('span',{staticClass:"mx-0 text-uppercase",staticStyle:{"font-weight":"500"}},[_vm._v(" Cantidad ")]),_c('span',{staticClass:"ml-1"},[_c('v-avatar',{attrs:{"size":"35"}},[_c('v-btn',{staticClass:"mx-2 mx-md-0 elevation-0",attrs:{"color":"white","fab":"","x-small":""},on:{"click":function($event){return _vm.HandlerQuantity('minus')}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-minus")])],1)],1),_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.quantity)+" ")]),_c('v-avatar',{attrs:{"size":"35"},on:{"click":function($event){return _vm.HandlerQuantity('plus')}}},[_c('v-btn',{staticClass:"mx-0 mx-md-0 elevation-0",attrs:{"color":"white","fab":"","x-small":""}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)],1)],1)]):_vm._e(),(
                _vm.validateStock() &&
                  _vm.validateUmbral() &&
                  _vm.dataProduct.store.out_stock == false
              )?_c('div',{staticClass:"d-md-flex justify-space-between mt-5",staticStyle:{"width":"80%"}},[_c('v-btn',{staticClass:"mt-1 mt-md-0 white--text",staticStyle:{"border-width":"medium"},attrs:{"rounded":"","color":"#00A0E9"},on:{"click":function($event){return _vm.HandlerAddCart()}}},[_vm._v(" Comprar ")]),_c('div',{staticClass:"d-flex mt-2 mt-md-0"},[_c('v-btn',{staticStyle:{"border-width":"medium"},attrs:{"fab":"","color":"#5A5953","x-small":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-share-variant-outline")])],1),_c('span',{staticClass:"mt-2 ml-1",staticStyle:{"font-size":"12px","font-weight":"600"}},[_vm._v(" COMPARTIR ")])],1)],1):_vm._e()],1),(!_vm.$vuetify.breakpoint.smAndDown && !_vm.messageProductAdd)?_c('div',{staticClass:"py-15"}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"py-md-15 py-10"}):_vm._e()]),(_vm.messageProductAdd)?_c('v-col',{attrs:{"offset-md":"6","md":"6"}},[_c('div',{staticClass:"mb-15 d-flex ml-7"},[_c('p',{staticStyle:{"color":"#00a0e9","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'cart' })}}},[_vm._v(" Agregaste "+_vm._s(_vm.dataProduct.keywords)+" x "+_vm._s(_vm.quantity)+". Ir al carrito. ")])])]):_vm._e()],1)],1)],1),_c('v-container',[_c('v-row',{staticClass:"d-flex",attrs:{"justify":"center"}},_vm._l((_vm.dataProduct.images),function(item,index){return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(index != 0),expression:"index != 0"}],key:index,staticClass:"mt-n15 mt-sm-n10 mt-md-n15 mr-3",attrs:{"width":"150px","height":"auto"},on:{"click":function () {
            _vm.showImageBackground = true;
            _vm.imageBackground = item;
          }}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-img',{class:hover ? 'elevation-15' : '',style:(hover
                ? 'cursor: pointer; opacity: 0.8; background-color: black; z-index:10;'
                : ''),attrs:{"contain":"","src":item,"lazy-src":item},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[(hover)?_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"fab":"","color":"white","small":""}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1):_vm._e()],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"py-5"}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.showImageBackground),callback:function ($$v) {_vm.showImageBackground=$$v},expression:"showImageBackground"}},[_c('v-card',{attrs:{"width":"600","height":"auto"}},[_c('v-img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.imageBackground,"lazy-src":_vm.imageBackground},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])})],1)],1),_c('div',{domProps:{"innerHTML":_vm._s(_vm.dataProduct.text_html)}}),_c('suscribe-component'),(_vm.showHasLimitNotification)?_c('notification-componenet',{attrs:{"active":_vm.showHasLimitNotification,"text":_vm.textHasLimit,"color":"#14A7EB"},on:{"notification:change":_vm.HandlerNotificationComponent}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }