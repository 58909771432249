<template>
  <div data-reamaze-embed="contact" data-reamaze-embed-form-id="25310"></div>
</template>

<script>
export default {
  mounted() {
    const remaze = window._support || { ui: {}, user: {} };
    _support["account"] = "edifiercl";
    _support["contact_custom_fields"] = _support["contact_custom_fields"] || {};
    _support["contact_custom_fields"]["rmz_form_id_25310"] = {};
  },
};
</script>

<style>
</style>
