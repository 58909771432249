<template>
  <div class="weight-ligth" v-if="price != null">
    <span v-if="price.discount === 0" class="text-20">
      <span class="weight-medium text-20 mr-3">
        $ {{ price.value | currencyPVP }}
      </span>
      <span >{{ price.paymentType }}</span>
      <br />
    </span>
    <span v-else class="text-20 pb-0 mb-0">
      <v-row>
        <v-col class="pb-0 mb-0">
          <span
            class="weight-medium text-12 text-decoration-line-through text--secondary"
          >
            $ {{ price.value_no_discount | currencyPVP }}
          </span>
          <span class="ml-3">{{ price.paymentType }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="weight-medium text-20">
            $ {{ price.value | currencyPVP }}
          </span>
          <span class="green--text text--darken-2 ml-3">
            {{ price.discount }}% de descuento
          </span>
        </v-col>
      </v-row>
    </span>
    <span
      v-if="price.paymentType === 'Precio Oficial'"
      class="text-15"
      style="color: #14a7eb"
    >
      Hasta 12 cuotas sin interés de ${{ (price.value / 12) | currencyPVP }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          // currency: "ARS",
          // style: "currency",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },

  data() {
    return {};
  }
};
</script>

<style>
.text-15 {
  font-size: 1.3em;
}
</style>
