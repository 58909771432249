var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',[(_vm.imageBanner() && _vm.productsCategories.length > 0)?_c('v-img',{attrs:{"contain":"","width":"100%","height":"auto","src":_vm.productsCategories[0].image_url,"lazy-src":_vm.productsCategories[0].image_url}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bold d-flex"},[_c('span',{staticStyle:{"color":"#00a0e9","font-size":"2.5em"}},[_vm._v(" "+_vm._s(_vm.productsCategories[0].name)+" ")])])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex",staticStyle:{"color":"white"}},[_vm._v(" Descubre tu mejor opción ")])])],1)],1)],1):_c('v-img',{attrs:{"contain":"","src":require("@/assets/img/sections/todoCategories.webp"),"lazy-src":require("@/assets/img/sections/todoCategories.webp")}})],1),_c('v-sheet',{attrs:{"color":"#F1F1F1"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"11"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex align-stretch my-5",attrs:{"cols":"12","md":"3"}},[_c('v-sheet',{attrs:{"width":"100%","height":"100%"}},[_vm._l((_vm.productsCategories),function(category,index){return _c('div',{key:index,staticClass:"px-5 py-5"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"text-capitalize",style:(hover
                          ? 'font-size: 17px; cursor: pointer; color: #00A0E9'
                          : 'font-size: 17px; cursor: pointer;'),on:{"click":function($event){return _vm.HandlerFilterCategory(category, 1)}}},[_vm._v(" "+_vm._s(category.name)+" ")])]}}],null,true)}),_vm._l((category.sub_category),function(sub_cat,index2){return _c('div',{key:index2,class:!sub_cat.can_see ? 'mb-5' : 'mb-n6'},[(sub_cat.can_see)?_c('v-checkbox',{attrs:{"label":_vm.subCatName(sub_cat.name),"color":"#00A0E9"},on:{"change":function($event){return _vm.HandlerFilterCategory(sub_cat, 2)}},model:{value:(sub_cat.value),callback:function ($$v) {_vm.$set(sub_cat, "value", $$v)},expression:"sub_cat.value"}}):_vm._e()],1)}),_c('v-divider',{staticClass:"mt-5"})],2)}),_c('div',{staticClass:"text-capitalize px-5 py-10",on:{"click":function($event){return _vm.HandlerFilterCategory({}, 3)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('div',{staticClass:"text-capitalize",style:(hover
                          ? 'font-size: 17px; cursor: pointer; color: #00A0E9'
                          : 'font-size: 17px; cursor: pointer;')},[_vm._v(" Todo ")])]}}])})],1)],2)],1),_c('v-col',{staticClass:"align-stretch my-5",attrs:{"cols":"12","md":"9"}},[_c('v-sheet',{staticClass:"mt-3",attrs:{"color":"white"}},[(_vm.productsData.length > 0)?_c('v-row',_vm._l((_vm.productsData),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"4","md":"4"}},[(item.product != null)?_c('v-card',{staticClass:"elevation-0",attrs:{"height":"200","flat":""},on:{"click":function($event){return _vm.HandlerShowProduct(item)}}},[(item.images == null)?_c('img',{attrs:{"aspect-ratio":4 / 3,"height":"200","width":"100%","contain":"","src":require("../../assets/img/no_image.jpg")}}):_c('div',[_c('v-img',{attrs:{"height":"200","width":"100%","contain":"","src":item.images[0],"lazy-src":item.images[0]},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)})],1),_c('v-card-text')],1):_vm._e(),_c('p',{staticClass:"\n                        text-center\n                        title\n                        font-weight-bold\n                        text-uppercase\n                      "},[_vm._v(" "+_vm._s(item.keywords)+" ")]),(
                        _vm.getPvpInfo(item).value >=
                          _vm.getPvpTransferInfo(item).value
                      )?_c('span',[_c('price-component',{attrs:{"price":_vm.getPvpTransferInfo(item)}}),_c('price-component',{attrs:{"price":_vm.getPvpInfo(item)}})],1):_c('span',[_c('price-component',{attrs:{"price":_vm.getPvpInfo(item)}}),_c('price-component',{attrs:{"price":_vm.getPvpTransferInfo(item)}})],1),_c('cp-information',{staticClass:"text-center",attrs:{"dataProduct":item,"authUser":_vm.authUser}})],1)}),1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"9"}},[_c('p',{staticClass:"text-center py-10 font-weight-medium"},[_vm._v(" No hay resultados para su busqueda ")])])],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[(_vm.paginationData.total > 0)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"color":"#00A0E9","length":_vm.paginationData.lastPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }