<template>
  <div>
    <div class="mb-5">
      <v-btn
        v-if="!inStock() || !validateUmbral()"
        @click.stop="showModalReserve = true"
        class="mt-0 white--text"
        rounded
        color="#00A0E9"
      >
        AVISAME
      </v-btn>
      <v-btn
        v-else-if="$route.name != 'product_details' && $route.name != 'cart'"
        @click="HandlerShowProduct(dataProduct)"
        class="mt-0 white--text"
        rounded
        color="#00A0E9"
      >
        COMPRAR
      </v-btn>
    </div>

    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-dialog
        v-if="showModalReserve"
        v-model="showModalReserve"
        max-width="600"
      >
        <v-card>
          <v-card-title>
            Completá con tus datos y nos comunicaremos
          </v-card-title>
          <v-card-text>
            <ValidationProvider
              name="nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup="passes(HandlerNotification)"
                filled
                rounded
                v-model="inputName"
                label="Nombre"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="email"
              rules="email|required"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup="passes(HandlerNotification)"
                filled
                rounded
                label="Email"
                v-model="inputEmail"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="teléfono"
              rules="numeric|min:8|required"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup="passes(HandlerNotification)"
                filled
                rounded
                label="Teléfono"
                v-model="inputPhone"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showModalReserve = false">Cancelar</v-btn>
            <v-btn
              :loading="loading"
              dark
              color="#00A0E9"
              @click="passes(HandlerNotification)"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {}
    },
    authUser: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false,
      showModalReserve: false,

      //Data
      email: "",
      name: "",
      phone: "",
      responseChazki: null,

      //Notification
      activeNotificacion: false,
      textNotification: "",
      colorNotification: "black"
    };
  },

  async created() {
    if (this.isAuth) {
      const responseChazki = await this.$store.dispatch(
        "products/CHAZKI_VALIDATE"
      );
      this.responseChazki = responseChazki.data.data;
    }
  },

  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    authUserData() {
      return this.$store.getters["auth/GET_PROFILE"];
    },

    inputName: {
      get() {
        return this.isAuth
          ? this.authUserData.buyer.first_name
          : this.customName;
      },
      set(value) {
        if (this.isAuth) {
          this.authUserData.buyer.first_name = value;
        } else {
          this.customName = value;
        }
      }
    },

    inputEmail: {
      get() {
        return this.isAuth ? this.authUserData.buyer.email : this.customEmail;
      },
      set(value) {
        if (this.isAuth) {
          this.authUserData.buyer.email = value;
        } else {
          this.customEmail = value;
        }
      }
    },

    inputPhone: {
      get() {
        return this.isAuth ? this.authUserData.buyer.phone : this.customPhone;
      },
      set(value) {
        if (this.isAuth) {
          this.authUserData.buyer.phone = value;
        } else {
          this.customPhone = value;
        }
      }
    }
  },

  methods: {
    HandlerReturnWarehouse(cp, warehouse) {
      switch (parseInt(cp)) {
        default:
          return this.getDepostCentral(warehouse, cp, 13);
      }
    },

    getDepostCentral(warehouse, cp, cenId) {
      const getWarehouseFwl01 = warehouse.find(
        value => value.warehouse_id == cenId
      );

      if (getWarehouseFwl01 != undefined) {
        if (getWarehouseFwl01.current_stock > 0) {
          if (cp >= 1000 && cp < 1441) {
            let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
            return `${text} dentro de las 24Hs HÁBILES`;
          } else if (this.responseChazki == true) {
            let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
            return `${text} dentro de las 72Hs HÁBILES`;
          } else {
            let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
            return `${text} en 4-6 días hábiles`;
          }
        } else {
          return "AVISAME";
        }
      }
    },

    getDeposit(warehouse, regId, cenId) {
      const getWarehouseReg = warehouse.find(
        value => value.warehouse_id == regId
      );

      const getWarehouseFwl01 = warehouse.find(
        value => value.warehouse_id == cenId
      );

      if (getWarehouseReg != undefined && getWarehouseFwl01 != undefined) {
        if (
          getWarehouseReg.current_stock > 0 &&
          getWarehouseFwl01.current_stock > 0
        ) {
          // if (this.warehouseValue === 5) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} dentro de las 24Hs HÁBILES `;
          // } else {
          //   let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          //   return `${text} en 4-6 días hábiles`;
          // }
        } else if (
          getWarehouseReg.current_stock === 0 &&
          getWarehouseFwl01.current_stock > 0
        ) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} en 4-6 días hábiles`;
        } else if (
          getWarehouseReg.current_stock > 0 &&
          getWarehouseFwl01.current_stock == 0
        ) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} dentro de las 24Hs HÁBILES `;
        } else if (
          getWarehouseReg.current_stock == 0 &&
          getWarehouseFwl01.current_stock == 0
        ) {
          return "AVISAME";
        }
      } else if (
        getWarehouseReg == undefined &&
        getWarehouseFwl01 != undefined
      ) {
        if (getWarehouseFwl01.current_stock > 0) {
          let text = this.$route.name == "cart" ? "Llega" : "RECIBILO";
          return `${text} dentro de las 72Hs HÁBILES`;
        } else if (getWarehouseFwl01.current_stock == 0) {
          return "AVISAME";
        }
      } else if (
        getWarehouseReg != undefined &&
        getWarehouseFwl01 == undefined
      ) {
        if (getWarehouseReg.current_stock > 0) {
          return "Entrega o Retira en el día";
        } else if (getWarehouseReg.current_stock == 0) {
          return "AVISAME";
        }
      }
    },

    ModalProductUser(zip_code, warehouse) {
      if (
        this.HandlerReturnWarehouse(zip_code, warehouse) == "AVISAME" &&
        this.dataProduct.user_product_notification == null
      ) {
        this.showModalReserve = true;
      }
    },

    async HandlerNotification() {
      try {
        this.loading = true;
        const requestForAuth = {
          publication_id: this.dataProduct.id,
          email: this.isAuth ? this.authUserData.buyer.email : this.customEmail,
          name: this.isAuth
            ? this.authUserData.buyer.first_name
            : this.customName,
          phone: this.isAuth ? this.authUserData.buyer.phone : this.customPhone
        };
        const request = { ...requestForAuth, country_id: 2 };

        if (this.isAuth) {
          await this.$store.dispatch(
            "products/PRODUCT_NOTIFICATION_BUYERS",
            requestForAuth
          );
        } else {
          await this.$store.dispatch(
            "products/PRODUCT_NOTIFICATION_USER",
            request
          );
        }

        this.showModalReserve = false;
        this.dataProduct.user_product_notification = true;
        this.$snotify.success(
          "Te avisaremos cuando tengamos disponible el producto .",
          "¡Exito!"
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.showModalReserve = false;
        this.loading = false;
      }
    },

    validateUmbral() {
      let threshold = 0;
      const dataProductValue = { ...this.dataProduct };

      if (
        dataProductValue.product != null &&
        dataProductValue.product.product_warehouse != null
      ) {
        const productWarehouse = dataProductValue.product.product_warehouse;
        const warehouse = productWarehouse.filter(whr => whr.current_stock > 0);

        if (warehouse.length === 0) return false;

        const warehouseThreshold = warehouse.some(
          whr => whr.current_stock > this.dataProduct.threshold
        );

        if (warehouseThreshold) {
          threshold = warehouse[0].current_stock - this.dataProduct.threshold;
        }
      }
      return threshold > 0 ? true : false;
    },

    HandlerShowProduct(publication) {
      const { product, keywords } = publication;
      this.$router.push({
        path:
          "/product-details/" +
          product?.brand.name +
          "/" +
          keywords.replaceAll(" ", "-")
      });
    },

    HandlerModalAvisame() {
      if (this.isAuth) {
        this.showModalReserve = true;
      } else {
        this.$router.push({ name: "login" });
      }
    },

    getMonth() {
      return moment()
        .add(1, "month")
        .locale("es")
        .format("MMMM");
    },

    centerElement(mb = "mb-0") {
      if (this.$route.name === "home") return `d-flex justify-start ${mb}`;
      return `d-flex justify-center ${mb}`;
    },

    inStock() {
      return (
        this.dataProduct.product.product_warehouse.some(
          ele => ele.current_stock !== 0
        ) && !this.dataProduct.store.out_stock
      );
    },

    userHasNotifications() {
      const notification = this.dataProduct.user_product_notification;
      if (notification === true) {
        return true;
      } else if (notification != null) {
        const test = notification.email === this.authUser.email;
        return test;
      } else {
        return false;
      }
    }
  }
};
</script>

<style></style>
