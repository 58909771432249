var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"position":"relative"}},[_c('v-carousel',{attrs:{"height":"100%","interval":5000,"hide-delimiter-background":true,"show-arrows-on-hover":false,"show-arrows":false,"cycle":_vm.activeCycle,"next-icon":"mdi-chevron-right"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},_vm._l((_vm.promotions[
          _vm.isDesktopDisplay ? 'desktop' : 'mobile'
        ].carrusel),function(item,i){return _c('v-carousel-item',{key:i,staticStyle:{"cursor":"pointer"},attrs:{"contain":"","reverse-transition":"fade-transition","transition":"fade-transition"},on:{"click":function($event){return _vm.redirectToUrl(item)}}},[_c('v-img',{staticClass:"carousel-img",staticStyle:{"height":"37vmax"},attrs:{"src":_vm.urlImage(item.image_url)}})],1)}),1),_c('v-btn',{staticClass:"simple-play-btn",style:({ left: ("calc(50% + " + _vm.playBtnLeft + "px + 10px)") }),on:{"click":_vm.handlerActiveCycle}},[(!_vm.activeCycle)?_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-play ")]):_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-pause ")])],1)],1),_c('v-container',{staticStyle:{"padding":"0"},attrs:{"fluid":""}},[_c('section',{staticClass:"my-4",attrs:{"id":"categorias"}},[_c('div',{staticClass:"featured-info"},[_c('div',{staticClass:"featured-title"},[_vm._v("Productos destacados")]),_c('div',{staticClass:"featured-text"},[_vm._v(" Conoce nuestra selección y conviértete en un #EDIFAN ")])]),_c('div',{staticClass:"featured-row mt-3"},_vm._l((_vm.promotions[
            _vm.isDesktopDisplay ? 'desktop' : 'mobile'
          ].destacados),function(item,index){return _c('div',{key:index,staticClass:"featured-item"},[_c('v-card',{class:index == 1 && _vm.$vuetify.breakpoint.name == 'sm'
                ? 'mx-1'
                : _vm.$vuetify.breakpoint.name == 'xs'
                ? 'my-1'
                : '',on:{"click":function($event){return _vm.redirectToUrl(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-img',{class:hover ? 'hvr-grow featured-image' : 'featured-image',attrs:{"gradient":!hover
                    ? 'to top, rgba(40, 41, 40, .15), rgba(24, 28, 31,.2)'
                    : '',"cover":"","src":_vm.urlImage(item.image_url)}})]}}],null,true)})],1)],1)}),0)])]),_c('div',{staticClass:"news-banners mt-12",style:(_vm.isDesktopDisplay ? 'display:flex' : 'display:none')},_vm._l((_vm.promotions.desktop.novedades),function(item,index){return _c('v-img',{class:index !== 0 ? 'news-image mt-8' : 'news-image',attrs:{"src":_vm.urlImage(item.image_url)},on:{"click":function($event){return _vm.redirectToUrl(item)}}})}),1),_c('div',{staticClass:"news-banners-mobile mt-12",style:(!_vm.isDesktopDisplay ? 'display:flex' : 'display:none')},_vm._l((_vm.promotions.mobile.novedades),function(item,index){return _c('v-img',{class:index !== 0 ? 'news-image-mobile mt-4' : 'news-image-mobile',attrs:{"src":_vm.urlImage(item.image_url)},on:{"click":function($event){return _vm.redirectToUrl(item)}}})}),1),_c('section',{attrs:{"id":"destacados"}},[_c('v-responsive',[_c('v-sheet',{staticClass:"mt-15",attrs:{"color":"#EBF1F7"}},[_c('v-container',{attrs:{"fluid":_vm.breakpoint > 900}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",staticStyle:{"padding":"60px 0"},attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticStyle:{"border-width":"medium"},attrs:{"dark":"","rounded":"","color":"#00A0E9"},on:{"click":function($event){return _vm.$router.push({ name: 'products' })}}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Ir a la tienda ")])])],1)],1)],1)],1)],1)],1),_c('suscribe-component')],1)}
var staticRenderFns = []

export { render, staticRenderFns }